.page-row {
  border: 0px solid #ccc;
}
.page-block {
  margin-bottom: 2em;
  border: 0px solid #666;
}
.page-block .full-center {
  cursor: pointer;
  height: 150px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}
.full-center {
  display: table;
}
.page-block-inner {
  border: 0px solid green;
}
.page-block-thumbnail {
  margin-bottom: 25px;
  text-align: center;
}
.page-block-title {
  color: #fff;
  font-size: 1.2em;
  letter-spacing: -0.5px;
  line-height: 1.2em;
  margin-bottom: 0px;
  max-width: 100%;
  font-weight: bold;
  position: relative;
  text-align: left;
}
.page-block-description {
  padding: 10px 0px 7px;
  line-height: 1.4em;
  color: #9a9a9a;
  font-size: 0.95em;
}
.page-block a,
.page-block a:hover {
  color: #fff;
}
.page-block-status {
  background: none repeat scroll 0 0 #9bc84a;
  color: #fff !important;
  font-size: 10px !important;
  padding: 4px 4px !important;
  position: absolute;
  text-transform: uppercase;
  top: 9px;
  z-index: 2;
}
.page-block-published {
  color: #1bc98e !important;
  font-size: 10px !important;
  font-weight: bold;
  padding: 4px 4px !important;
  position: absolute;
  top: 9px;
  z-index: 2;
  right: 6px;
}
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.navbar-brand {
  color: #1bc98e !important;
  font-weight: 400;
}
.navbar-default {
  background-color: #fff !important;
  border-bottom: 1px solid #dddddd !important;
  border-top: 0px !important;
}
.panel-default > .panel-heading {
  background-color: #f0ffff;
  border-bottom: 2px solid #1bc98e;
  background-image: url("/images/easylanguagetyping/bg/easyarabictyping.png");
}
.panel-default {
  border: 1px solid #1bc98e !important;
}
.editor-toolbar {
  height: 30px;
}
.editor-content {
  padding-bottom: 15px;
}
.notification {
  background: #1bc98e;
  color: white;
  width: 200px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 1em;
  display: none;
  z-index: 1000;
}
.horizontal-block {
  padding: 25px 0px;
}
.shadow-block {
  background-color: #f0ffff;
}
.first-block {
  margin-top: -18px;
}
.full-width {
  width: 100%;
}
.internal-ads {
  height: 100px;
  color: #444444;
  background-color: #ffffff;
  margin: 20px 6px 0px 6px;
  border-radius: 3px;
}
.media-heading {
  margin-top: 10px;
  font-size: 18px;
}

/*# sourceMappingURL=linux.css.map */